import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'

import { isLoggedIn, checkUpdateUser } from '@/util/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: '/edicoes',
        name: 'Edições',
        component: () => import('@/views/EdicoesView.vue'),
      },
      {
        path: '/edicoes/:id',
        props: true,
        name: 'Edição',
        component: () => import('@/views/EdicaoView.vue'),
      },
      {
        path: '/premiacoes',
        name: 'Premiações',
        component: () => import('@/views/PremiacoesView.vue'),
      },
      {
        path: '/premiacoes/:id',
        props: true,
        name: 'Premiação',
        component: () => import('@/views/PremiacoesDetalhesView.vue'),
      },
      {
        path: '/edicoes/:id/:escola',
        props: true,
        name: 'Detalhes da Edição',
        component: () => import('@/views/DetalhesEdicaoView.vue'),
      },
      // {
      //   path: '/edicoes/:id/certificados/:de',
      //   props: true,
      //   name: 'Certificados da Edição DE',
      //   component: () => import('@/views/CertificadosDeView.vue'),
      // },
      {
        path: '/relatorio/:id',
        props: true,
        name: 'Relatório',
        component: () => import('@/views/RelatorioEdicaoView.vue'),
      },
      {
        path: '/professores',
        props: true,
        name: 'Professores',
        component: () => import('@/views/ProfessoresView.vue'),
      },
      {
        path: '/professores/:escola',
        props: true,
        name: 'Detalhes Professores',
        component: () => import('@/views/DetalhesProfessoresView.vue'),
      },
      {
        path: '/perfil',
        name: 'Perfil',
        component: () => import('@/views/PerfilView.vue'),
      },
    ],
  },
  {
    path: '/atualizacao',
    name: 'Atualização',
    component: () => import('@/views/AtualizacaoView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/esqueci-a-senha',
    name: 'Esqueci a senha',
    component: () => import('@/views/EsqueciSenhaView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/redefinir-senha/:token',
    props: true,
    name: 'Redefinir senha',
    component: () => import('@/views/RedefinirSenhaView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/confirmacao/:token',
    props: true,
    name: 'Confirmação',
    component: () => import('@/views/ConfirmacaoView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/ativacao',
    name: 'Reenviar E-mail',
    component: () => import('@/views/ReenviarEmailView.vue'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/manutencao',
    name: 'Manutencao',
    component: () => import("@/views/ManutencaoView.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  // {
  //   path: '/esqueci-senha',
  //   name: 'Esquecia a senha',
  //   component: () => import('@/views/RecoverPasswordView.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //   },
  // },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  console.log()
  if (to.meta.allowAnonymous && isLoggedIn()) {
    next({ path: '/' })
  } else if (!to.meta.allowAnonymous && !isLoggedIn()) {
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    })
  } else if (isLoggedIn() && checkUpdateUser() && to.path !== '/atualizacao') {
    next({ path: '/atualizacao' })
  }
  else {
    next()
  }
})


export default router